@import 'variables.css';

.productos-lista {
  position: relative;
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
}

.productos-header {
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: 1rem;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productos-titulo {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.header-acciones {
  display: flex;
  gap: 1rem;
}

.header-btn {
  background: none;
  border: none;
  color: var(--background-color);
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.header-btn:hover {
  transform: scale(1.1);
}

.filtros-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: var(--secondary-color);
}

.filtros-container.abierto {
  max-height: 500px; /* Ajusta este valor según sea necesario */
}

.filtros-contenido {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.filtro-item {
  flex: 1 1 200px;
}

.filtro-item label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: var(--text-color);
}

.filtro-item input,
.filtro-item select {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid var(--background-color);
  border-radius: var(--border-radius);
  font-size: 1rem;
  transition: all 0.3s ease;
}

.filtro-item input:focus,
.filtro-item select:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.25);
}

.productos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.producto-card {
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.producto-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.producto-imagen {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.producto-info {
  padding: 0rem;
}

.producto-nombre {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

.producto-categoria {
  font-size: 0.9rem;
  color: var(--text-color);
  opacity: 0.7;
  margin-bottom: 1rem;
}

.producto-precio {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--accent-color);
}

.producto-acciones {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.btn-accion {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: var(--border-radius);
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-detalle {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.btn-editar {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.btn-eliminar {
  background-color: var(--danger-color);
  color: var(--background-color);
}

.btn-accion:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.paginacion {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.btn-paginacion {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  color: var(--background-color);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-paginacion:hover {
  background-color: var(--accent-color);
}

.btn-paginacion:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Estilos para el botón de añadir producto */
.btn-anadir-producto {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: var(--accent-color, #3498db); /* Color de respaldo si la variable no está definida */
  color: white;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  text-decoration: none; /* Para quitar el subrayado del enlace */
}

.btn-anadir-producto:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
}

.btn-anadir-producto:active {
  transform: scale(0.95);
}

/* Ajustes responsivos para el botón de añadir producto */
@media (max-width: 768px) {
  .btn-anadir-producto {
    bottom: 1.5rem;
    right: 1.5rem;
    width: 60px;
    height: 60px;
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .productos-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 1rem;
  }

  .filtros-contenido {
    flex-direction: column;
  }

  .filtro-item {
    width: 100%;
  }
}