@import 'variables.css';

.notificaciones, .alertas {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1001;
}

.notificacion, .alerta {
  background-color: #4CAF50;
  color: white;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  animation: fadeIn 0.3s, fadeOut 0.3s 4.7s;
}

.notificacion.warning, .alerta.warning {
  background-color: #ff9800;
}

.notificacion.error, .alerta.error {
  background-color: #f44336;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.notificaciones-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  max-width: 300px;
}

.notificacion {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 0.5rem;
  padding: 0.75rem 1rem;
  max-width: 300px;
  animation: slideIn 0.3s ease-out;
}

.notificacion.warning {
  border-left: 4px solid #ffc107;
}

.notificacion.info {
  border-left: 4px solid #17a2b8;
}

.notificacion-icono {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.notificacion.warning .notificacion-icono {
  color: #ffc107;
}

.notificacion.info .notificacion-icono {
  color: #17a2b8;
}

.notificacion-contenido {
  flex-grow: 1;
}

.notificacion-contenido p {
  margin: 0;
  font-size: 0.9rem;
  color: #333;
}

.notificacion-cerrar {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 0.5rem;
  padding: 0;
}

@keyframes slideIn {
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}