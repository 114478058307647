.formulario-producto {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--bg-color);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
}

.formulario-producto:hover {
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15);
  transform: translateY(-8px);
}

.formulario-producto h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--text-color);
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.paso {
  opacity: 0;
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  transform: translateY(30px);
}

.paso.activo {
  opacity: 1;
  transform: translateY(0);
}

.form-group {
  margin-bottom: 2.5rem;
  border-bottom: 2px solid var(--accent-color);
  padding-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 1rem;
  font-weight: 600;
  color: var(--text-color);
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.form-group:hover label {
  color: var(--accent-color);
}

.form-group input {
  width: 100%;
  padding: 1rem 1.5rem;
  border: 2px solid var(--secondary-color);
  border-radius: 12px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  background-color: var(--background-color);
  color: var(--text-color);
}

.form-group input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 5px rgba(52, 152, 219, 0.25);
}

.navegacion-formulario {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem; /* Añade espacio entre los botones */
}

.btn-nav {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1; /* Hace que todos los botones tengan el mismo ancho */
}

.btn-anterior {
  background-color: var(--secondary-color, #f0f0f0);
  color: var(--text-color);
}

.btn-siguiente {
  background-color: var(--accent-color, #3498db);
  color: white;
}

.btn-guardar {
  background-color: var(--success-color, #4CAF50);
  color: white;
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
}

.btn-nav:hover {
  opacity: 0.9;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.btn-nav:active {
  transform: translateY(0);
}

.btn-nav:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.btn-nav:not(:disabled) {
  opacity: 1;
  pointer-events: auto;
}

.progreso-formulario {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.paso-indicador {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  margin: 0 0.5rem;
  transition: all 0.4s ease;
  cursor: pointer;
}

.paso-indicador.activo {
  background-color: var(--accent-color);
  transform: scale(1.3);
}

@media (max-width: 768px) {
  .formulario-producto {
    padding: 1rem;
    margin: 1rem;
  }

  .formulario-producto h2 {
    font-size: 1.8rem;
  }

  .btn-nav {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }

  .navegacion-formulario {
    flex-direction: column;
    gap: 1rem;
  }

  .btn-nav {
    width: 100%;
    justify-content: center;
  }

  .btn-guardar {
    margin-top: 1rem;
  }
}

/* Animaciones */
@keyframes fadeInUp {
  from { 
    opacity: 0; 
    transform: translateY(30px); 
  }
  to { 
    opacity: 1; 
    transform: translateY(0); 
  }
}

.formulario-producto {
  animation: fadeInUp 0.6s ease-out;
}

.form-group {
  animation: fadeInUp 0.5s ease-out;
  animation-fill-mode: both;
}

.form-group:nth-child(1) { animation-delay: 0.1s; }
.form-group:nth-child(2) { animation-delay: 0.2s; }
.form-group:nth-child(3) { animation-delay: 0.3s; }
.form-group:nth-child(4) { animation-delay: 0.4s; }

/* Estilos para modo oscuro */
.modo-oscuro .formulario-producto {
  background-color: var(--dark-background-color);
  color: var(--dark-text-color);
}

.modo-oscuro .form-group input {
  background-color: var(--dark-secondary-color);
  color: var(--dark-text-color);
  border-color: var(--dark-primary-color);
}

.modo-oscuro .btn-anterior {
  background-color: var(--dark-secondary-color);
}

.modo-oscuro .btn-siguiente,
.modo-oscuro .btn-enviar {
  background-color: black;
}

.modo-oscuro .paso-indicador {
  background-color: var(--dark-secondary-color);
}

.modo-oscuro .paso-indicador.activo {
  background-color: black;
}

/* Efectos de hover adicionales */
.form-group input:hover {
  border-color: var(--accent-color);
}

.paso-indicador:hover {
  transform: scale(1.2);
}

/* Transición suave entre pasos */
.paso {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.paso.activo {
  transition-delay: 0.1s;
}

.formulario-producto {
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.formulario-producto label {
  display: block;
  margin-bottom: 0.5rem;
}

.formulario-producto input,
.formulario-producto select {
  width: 100%;
  margin-bottom: 1rem;
}

.formulario-producto button {
  margin-top: 1rem;
}