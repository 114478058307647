:root {
  --primary-color: #000000;
  --secondary-color: #ffffff;
  --accent-color: #000000;
  --background-color: #ecf0f1;
  --text-color: #white;
  --success-color: #2ecc71;
  --warning-color: #f39c12;
  --danger-color: #e74c3c;
  --border-radius: 8px;
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;

  /* Modo oscuro */
  --dark-primary-color: #white;
  --dark-secondary-color: #34495e;
  --dark-background-color: #1a1a1a;
  --dark-text-color: #ecf0f1;
}

.modo-oscuro {
  --primary-color: #000000;
  --secondary-color: #ffffff;
  --background-color: #333333;
  --text-color: #black;
}