@import 'variables.css';

:root {
  --bg-color: #ffffff;
  --text-color: #000000;
  --border-color: #333333;
  --hover-color: #e0e0e0;
  --active-color: #cccccc;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: Arial, sans-serif;
}

.modo-oscuro {
  --bg-color: #000000;
  --text-color: #ffffff;
  --border-color: #cccccc;
  --hover-color: #333333;
  --active-color: #666666;
}

button, input, select {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  border-radius: 4px;
}

button:hover, input:hover, select:hover {
  background-color: var(--hover-color);
}

button:active, input:active, select:active {
  background-color: var(--active-color);
}

.App {
  font-family: 'Arial', sans-serif;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
}

@media (min-width: 768px) {
  .App {
    max-width: 800px;
    padding: 20px;
  }
}

.conexion-indicador {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
  position: relative;
}

.conexion-indicador svg {
  font-size: 0.8em;
}

.conexion-indicador .ping {
  font-size: 0.8em;
  color: var(--text-color-secondary);
}

.conexion-indicador .info-icon {
  cursor: pointer;
  color: var(--accent-color);
}

.conexion-detalles {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
}

.conexion-detalles p {
  margin: 5px 0;
  font-size: 0.8em;
}

.btn-agregar-producto {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  transition: all 0.3s ease;
  padding: 0; /* Elimina el padding */
}

.btn-agregar-producto svg {
  width: 24px; /* Ajusta el tamaño del icono */
  height: 24px;
}

.btn-agregar-producto:hover {
  background-color: #45a049;
  transform: scale(1.1);
}

.btn-agregar-producto:active {
  background-color: #3e8e41;
  transform: scale(0.95);
}

/* Si quieres que el botón sea rectangular en lugar de circular, puedes usar estos estilos alternativos: */
/*
.btn-agregar-producto {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  transition: all 0.3s ease;
}
*/

/* Asegúrate de que el botón se vea bien en modo oscuro */
.modo-oscuro .btn-agregar-producto {
  background-color: #45a049;
}

.modo-oscuro .btn-agregar-producto:hover {
  background-color: #4CAF50;
}

.modo-oscuro .btn-agregar-producto:active {
  background-color: #3e8e41;
}