@import 'variables.css';

:root {
  --header-bg-color: #000000;
  --header-text-color: #ffffff;
  --header-hover-color: #333333;
  --header-active-color: #666666;
}

.header {
  background-color: var(--primary-color);
  padding: 0.5rem;
  color: white;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.app-title {
  font-size: 1.2rem;
  margin: 0;
}

.user-greeting {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.user-greeting svg {
  margin-right: 0.3rem;
}

.modo-oscuro-toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
}

.main-nav {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.nav-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.7rem;
}

.nav-btn svg {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
}

.nav-btn.active {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.nav-text {
  display: none;
}

@media (min-width: 768px) {
  .header {
    padding: 1rem;
  }

  .app-title {
    font-size: 1.5rem;
  }

  .user-greeting {
    font-size: 1rem;
  }

  .nav-btn {
    font-size: 0.8rem;
    padding: 0.7rem;
  }

  .nav-btn svg {
    font-size: 1.5rem;
  }

  .nav-text {
    display: block;
  }
}