.detalles-producto {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 1.25rem;
  overflow: hidden;
  background-color: var(--background-color);
}

.producto-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.producto-header h2 {
  font-size: 1.75rem;
  color: var(--primary-color);
  margin: 0;
  font-weight: 600;
}

.producto-acciones {
  display: flex;
  gap: 0.75rem;
}

.btn {
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.btn-editar {
  background-color: var(--accent-color);
  color: white;
}

.btn-eliminar {
  background-color: var(--danger-color);
  color: white;
}

.btn-volver {
  background-color: var(--secondary-color);
  color: white;
}

.btn:hover {
  opacity: 0.9;
}

.producto-contenido {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.producto-imagen {
  flex: 1;
  max-width: 32%;
}

.producto-imagen img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.producto-info {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.info-basica, .metricas-financieras {
  flex: 1;
  overflow-y: auto;
  padding-right: 0.75rem;
}

.info-basica p, .metricas-financieras p {
  margin: 0.6rem 0;
  font-size: 1rem;
}

.metricas-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 0.75rem;
}

.metrica {
  background-color: var(--background-color-secondary);
  padding: 0.75rem;
  border-radius: var(--border-radius);
  text-align: center;
}

.metrica-titulo {
  font-size: 0.9rem;
  color: var(--text-color-secondary);
}

.metrica-valor {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--primary-color);
}

.grafico-rentabilidad {
  flex: 1;
  min-height: 220px;
  margin-top: 1.25rem;
}

@media (max-width: 768px) {
  .producto-contenido {
    flex-direction: column;
  }

  .producto-imagen {
    max-width: 100%;
  }

  .metricas-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}