@import 'variables.css';

.resumen {
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
}

.resumen h2 {
  margin-bottom: 1rem;
  color: var(--primary-color);
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.estadisticas-generales {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.estadistica-card {
  background-color: var(--secondary-color);
  border-radius: 8px;
  padding: 0.8rem;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  position: relative;
}

.estadistica-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.estadistica-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, var(--primary-color), #4a90e2);
}

.estadistica-icono {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  opacity: 0.8;
}

.estadistica-card h3 {
  margin: 0 0 0.3rem 0;
  font-size: 0.9rem;
  color: #555;
  font-weight: 600;
}

.estadistica-card p {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary-color);
  line-height: 1.2;
}

.producto-mas-rentable {
  background-color: var(--secondary-color);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.producto-mas-rentable::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, #ffd700, #ffa500);
}

.producto-mas-rentable-icono {
  font-size: 2rem;
  color: #ffd700;
  margin-bottom: 0.5rem;
}

.producto-mas-rentable h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  color: #555;
  font-weight: 600;
}

.producto-mas-rentable p {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary-color);
}

.graficos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.grafico-card {
  background-color: var(--secondary-color);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.grafico-card:hover {
  transform: translateY(-3px);
}

.grafico-card h3 {
  margin: 0 0 0.8rem 0;
  font-size: 1rem;
  color: #333;
  text-align: center;
  font-weight: 600;
}

.acciones-resumen {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.btn {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.btn-secondary {
  background-color: #e9ecef;
  color: #495057;
}

.btn-back {
  background-color: #6c757d;
  color: var(--secondary-color);
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 10px rgba(0,0,0,0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--secondary-color);
  min-width: 120px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  z-index: 1;
  border-radius: 8px;
  overflow: hidden;
}

.dropdown-content button {
  color: #333;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.9rem;
}

.dropdown-content button:hover {
  background-color: #f1f3f5;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media (max-width: 600px) {
  .estadisticas-generales {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .graficos {
    grid-template-columns: 1fr;
  }

  .btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
}