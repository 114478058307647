@import 'variables.css';

.producto-item {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
  border-radius: var(--border-radius);
  padding: 1rem;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.producto-item:hover, .producto-item:focus {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.producto-imagen {
  width: 100%;
  height: 150px;
  margin-bottom: 1rem;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.producto-imagen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imagen-placeholder {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #888;
}

.producto-info {
  flex: 1;
}

.producto-info h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
}

.producto-info .categoria {
  font-size: 0.9rem;
  color: #666;
  margin: 0 0 0.5rem 0;
}

.producto-detalles {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.producto-detalles p {
  margin: 0;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .producto-item {
    flex-direction: row;
    align-items: center;
  }

  .producto-imagen {
    width: 150px;
    height: 150px;
    margin-right: 1rem;
    margin-bottom: 0;
  }
}