.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 0.5s ease-out;
}

.splash-content {
  text-align: center;
  animation: fadeIn 1s ease-out;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 100px;
  height: 100px;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--background-color);
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: pulse 2s infinite;
}

.splash-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--primary-color);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.bar {
  width: 4px;
  height: 18px;
  margin: 0 8px;
  border-radius: 4px;
  animation: loading 1s ease-in-out infinite;
}

.bar:nth-child(1) {
  background-color: var(--primary-color);
  animation-delay: 0s;
}

.bar:nth-child(2) {
  background-color: var(--secondary-color);
  animation-delay: 0.09s;
}

.bar:nth-child(3) {
  background-color: var(--primary-color);
  animation-delay: .18s;
}

.bar:nth-child(4) {
  background-color: var(--secondary-color);
  animation-delay: .27s;
}

.splash-content p {
  font-size: 1.2rem;
  color: var(--text-color);
  opacity: 0.8;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1, 2.2);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modo-oscuro .splash-screen {
  background-color: var(--dark-background-color);
}

.modo-oscuro .logo {
  background-color: var(--dark-primary-color);
  color: var(--dark-background-color);
}

.modo-oscuro .splash-content h1 {
  color: var(--dark-primary-color);
}

.modo-oscuro .bar:nth-child(1),
.modo-oscuro .bar:nth-child(3) {
  background-color: var(--dark-primary-color);
}

.modo-oscuro .bar:nth-child(2),
.modo-oscuro .bar:nth-child(4) {
  background-color: var(--dark-secondary-color);
}

.modo-oscuro .splash-content p {
  color: var(--dark-text-color);
}