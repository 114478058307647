.ajuste-tarifas {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--background-color);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ajuste-tarifas h2 {
  color: var(--primary-color);
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.ajuste-tarifas h3 {
  color: var(--text-color);
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.info-tarifas {
  background-color: var(--info-background-color, #e7f3fe);
  border-left: 4px solid var(--info-border-color, #3498db);
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
}

.info-icon {
  color: var(--info-icon-color, #3498db);
  font-size: 1.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
}

.info-tarifas p {
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--text-color);
}

.tarifa-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
}

.tarifa-form input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.tarifa-form input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.btn-primary, .btn-secondary {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.btn-primary {
  grid-column: 1 / -1;
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: var(--primary-color-dark);
  transform: translateY(-2px);
}

.btn-secondary {
  grid-column: 1 / -1;
  background-color: var(--danger-color);
  color: white;
}

.btn-secondary:hover {
  background-color: var(--danger-color-dark);
  transform: translateY(-2px);
}

.lista-tarifas {
  display: grid;
  gap: 1rem;
}

.tarifa-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-color-secondary);
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tarifa-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tarifa-nombre {
  font-size: 1.1rem;
  color: var(--text-color);
  font-weight: bold;
}

.tarifa-porcentaje {
  font-size: 1.1rem;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.tarifa-acciones {
  display: flex;
  gap: 0.5rem;
}

.btn-edit, .btn-delete {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: var(--text-color-secondary);
  padding: 0.25rem;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.btn-edit:hover {
  color: var(--primary-color);
  background-color: rgba(52, 152, 219, 0.1);
}

.btn-delete:hover {
  color: var(--danger-color);
  background-color: rgba(231, 76, 60, 0.1);
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: var(--text-color);
}

.error {
  color: var(--danger-color);
}

@media (max-width: 600px) {
  .tarifa-form {
    grid-template-columns: 1fr;
  }

  .tarifa-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .tarifa-acciones {
    align-self: flex-end;
  }
}